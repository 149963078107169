<template>
  <div>
    <v-card outlined>
      <v-card outlined>
        <v-card-title
          >Automações cadastradas
          <v-spacer></v-spacer>
          <v-btn icon color="success" @click="loadData"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="getAutomations"
            :search="search"
          >
            <template slot="item.when" slot-scope="{ item }">
              <div v-for="(w, idx) in item.when" :key="idx + 'a'">
                <span class="smallText font-weight-black">Dispositivo: </span>
                <span class="smallText font-weight-medium mx-2"
                  >{{ w.device.name }}
                </span>
                <span
                  class="smallText font-weight-black"
                  v-if="w.attributes.type == 'event'"
                  >Evento:</span
                >
                <span class="smallText font-weight-black" v-else>Estado:</span>
                <span class="smallText font-weight-medium mx-2"
                  >{{ w.attributes.name }}
                </span>
                <span
                  class="smallText font-weight-black mx-1 text-decoration-underline"
                  v-if="idx + 1 != item.when.length"
                  >{{ w.or ? "OU" : "E" }}
                </span>
              </div>
            </template>
            <template slot="item.do" slot-scope="{ item }">
              <!-- {{ item.do.length }} -->
              <div v-for="(w, idx) in item.do" :key="idx + 'a'">
                <span class="smallText font-weight-black">Dispositivo: </span>
                <span class="smallText font-weight-medium mx-2">{{
                  w.device.name
                }}</span>
                <span class="smallText font-weight-black">Ação:</span>
                <span class="smallText font-weight-medium mx-2"
                  >{{ w.action.name }}
                </span>
                <span class="smallText font-weight-black" v-if="w.delay > 0"
                  >Atrazo:</span
                >
                <span
                  class="smallText font-weight-medium ml-1"
                  v-if="w.delay > 0"
                  >{{ w.delay / 1000 }} segs
                </span>
              </div>
            </template>
            <template slot="item.enabled" slot-scope="{ item }">
              {{ item.enabled ? "Ativo" : "Inativo" }}
            </template>
            <template slot="item.clientId" slot-scope="{ item }">
              {{
                item.clientId
                  ? $store.getters.getClienteNameById(item.clientId)
                  : ""
              }}
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <!-- botão editar -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editar(item)"
                    color="warning"
                    class="mr-1"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar : {{ item.name }}</span>
              </v-tooltip>
              <!-- botão excluir -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="excluir(item)"
                    color="red"
                    >mdi-trash-can</v-icon
                  >
                </template>
                <span>Excluir : {{ item.name }}</span>
              </v-tooltip>
              <!-- botão comandos -->
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="runAutomation(item)"
                    >mdi-powershell</v-icon
                  >
                </template>
                <span>Executar : {{ item.name }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
    <!-- dialog de cadastro -->
    <DialogForm
      :auto="automation"
      :devices="getDevices"
      v-model="dialogForm"
      @save="save"
      @cancel="cancel"
    />
    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogForm = true"
          v-show="$route.query.clientId"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogForm from "./components/DialogForm.vue";
export default {
  name: "AutomacaoView",
  components: {
    DialogForm,
  },
  data() {
    return {
      dialogWhen: false,
      dialogDo: false,
      dialogForm: false,
      type: "device",
      devices: [],
      automation: {
        name: "",
        enabled: true,
        when: [],
        do: [],
      },
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nome do Equipamento",
          align: "start",
          value: "name",
        },
        { text: "Quando fazer", value: "when" },
        { text: "Oque fazer", value: "do" },
        { text: "Ativo", value: "enabled" },
        { text: "Cliente", value: "clientId" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getDevices", "getDevicesAvailable", "getAutomations"]),
  },
  methods: {
    // Metodos
    addWhen(data) {
      this.automation.when.push(data);
    },
    addDo(data) {
      this.automation.do.push(data);
    },
    save(auto) {
      this.automation = auto;
      this.automation.clientId = this.$route.query.clientId;
      this.$store.dispatch("saveAutomation", this.automation);
      this.cancel();
    },
    editar(item) {
      this.automation = item;
      this.dialogForm = true;
    },
    excluir(item) {
      this.$store.dispatch("deletAutomation", item);
    },
    cancel() {
      this.automation = {
        name: "",
        enabled: true,
        when: [],
        do: [],
      };
    },
    runAutomation(automation) {
      this.$http.post("automation/run", automation).then((res) => {
        console.log(res);
      });
    },
    loadData() {
      if (this.$route.query.clientId) {
        this.$store.dispatch(
          "loadDevicesByCLientId",
          this.$route.query.clientId
        );
        this.$store.dispatch(
          "loadAutomationsByClientId",
          this.$route.query.clientId
        );
      } else {
        this.$store.dispatch("loadDevices");
        this.$store.dispatch("loadAutomations");
      }
    },
  },
  mounted() {
    this.$store.dispatch("loadDevicesAvailable");
    this.loadData();
    if (this.$route.query.clientId) {
      this.headers.splice(5, 1);
    }
  },
};
</script>

<style>
.smallText {
  font-size: 0.8rem;
}
</style>