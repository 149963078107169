<template>
  <div>
    <v-alert
      color="cyan"
      border="left"
      elevation="2"
      colored-border
      v-show="clientName.length > 0"
    >
      Cliente: <strong>{{ clientName }}</strong>
    </v-alert>
    <v-tabs fixed-tabs show-arrows dark class="rounded-b-0">
      <v-tab
        ><v-icon class="mr-1">mdi-robot-industrial</v-icon>Automações</v-tab
      >
      <v-tab-item> <Automacao /> </v-tab-item>
      <v-tab
        ><v-icon class="mr-1"> mdi-toggle-switch</v-icon>Dispositivos</v-tab
      >
      <v-tab-item>
        <Dispositivos />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dispositivos from "./Dispositivos/Dispositivos.vue";
import Automacao from "./Automacao/Automacao.vue";
export default {
  name: "ClienteAutomacao",
  components: {
    Dispositivos,
    Automacao,
  },
  computed: {
    ...mapGetters(["getClienteNameById", "getClientes"]),
  },
  data: () => ({
    clientName: "",
  }),
  created() {
    if (this.$route.query.clientId) {
      this.clientName = this.getClienteNameById(this.$route.query.clientId);
    } else {
      if (this.getClientes.length == 0) {
        this.$store.dispatch("loadClientes");
      }
    }
  },
};
</script>

<style>
</style>