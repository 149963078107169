<template>
  <v-card outlined>
    <v-card-title>O que fazer</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn color="success" class="mx-2" @click="$emit('dialogDo')">
            <v-icon>mdi-plus-outline</v-icon> Criar
          </v-btn>
          <v-btn color="error" disabled>
            <v-icon>mdi-close-outline</v-icon> Remover</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Dispositivo</th>
                  <th class="text-left">Ação</th>
                  <th class="text-left">Atrazo</th>
                  <th class="text-left">Remover</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in toDo" :key="index">
                  <td>{{ item.device.name }}</td>
                  <td>{{ item.action.name }}</td>
                  <td>{{ item.delay / 1000 }} segs</td>
                  <td>
                    <v-btn icon @click="$emit('remove', index)">
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TableDo",
  props: ["value", "toDo"],
};
</script>

<style>
</style>