<template>
  <v-dialog v-bind:value="value" persistent scrollable>
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Oque fazer</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <!-- linha do dispositivo -->
        <v-row>
          <!-- coluna da selecção de dispositivo -->
          <v-col>
            <v-card min-height="500">
              <v-card-title>Selecione o dispositivo</v-card-title>
              <v-card-text>
                <v-card outlined class="px-1">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in devices"
                      :key="index"
                      selectable
                      :class="
                        selected.id == item.id ? 'light-blue darken-1' : ''
                      "
                      @click="selectDevice(item)"
                    >
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- coluna da selecção de estado eveto -->
          <v-col class="mb-0">
            <v-card>
              <v-card-title>Ação</v-card-title>
              <v-card-text class="mb-0">
                <v-text-field
                  label="Pesquisar"
                  outlined
                  class="mb-0"
                  v-model="search"
                  @input="filter"
                >
                </v-text-field>
                <v-card outlined class="px-1">
                  <v-list>
                    <v-list-item
                      :class="
                        action.event == item.event ? 'light-blue darken-1' : ''
                      "
                      v-for="(item, index) in actions"
                      :key="index"
                      @click="selectAction(item)"
                    >
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                </v-card>
                <v-text-field
                  class="mt-2"
                  outlined
                  label="Tempo de espera para a execução do comando"
                  type="time"
                  step="1"
                  v-model="delayStr"
                  @change="parseDelay"
                  v-show="actions.length"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mx-2" text @click="save"> Adicionar </v-btn>
        <v-btn color="warning" class="mx-2" text @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import TimeSelector from "./TimeSelector.vue";
export default {
  name: "DialogDo",
  components: {
    // TimeSelector,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    delayStr: "00:00:00",
    selected: {},
    actions: [],
    actionsRec: [],
    action: {},
    delay: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
        search: "",
  }),
  methods: {
    close() {
      this.selected = {};
      this.action = {};
      this.actions = [];
      this.$emit("input", false);
      this.delayStr = "00:00:00";
      this.delay = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    },
    selectDevice(selected) {
      this.selected = selected;
      // let device = this.$store.getters.getDevicesAvailable.find(
      //   (el) => el.class == selected.deviceType
      // );
      // if (device) {
      //   this.actions = device.actions;
      // }
      this.$http("device/config/" + selected.id).then((response) => {
        if (response.data.events) {
          this.actions = response.data.actions;
          this.actionsRec = [...this.actions];
          this.search = "";
        }
      });
    },
    selectAction(action) {
      this.action = action;
      console.log("selectAction", action);
    },
    save() {
      let toDo = {
        device: { name: this.selected.name, id: this.selected.id },
        action: this.action,
        // transforma o objeto em milisegundos
        delay: this.transformDelay2Millis(this.delay),
      };
      console.log("save", toDo);
      if (toDo.device.id == undefined) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "É necessário selecionar o Dispositivo"
        );
        return;
      }
      if (toDo.action.type == undefined) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "É necessário selecionar a Ação"
        );
        return;
      }
      // se a ação possuir delay transforma ele, adiciona o delay
      if (this.action.delay) {
        toDo.action.delay = this.transformDelay2Millis(this.action.delay);
      }
      this.$emit("save", toDo);
      this.close();
    },
    transformDelay2Millis(delay) {
      let total = delay.hours * 3600 + delay.minutes * 60 + delay.seconds;
      return total * 1000;
    },
    parseDelay() {
      let [hours, minutes, seconds] = this.delayStr.split(":");
      this.delay = {
        hours: parseInt(hours),
        minutes: parseInt(minutes),
        seconds: parseInt(seconds),
      };
      console.log(
        "parseDelay",
        this.delay,
        this.transformDelay2Millis(this.delay)
      );
    },
    filter() {
      const filterModelStr = (str = "", search = "") =>
        str.toLowerCase().includes(search.toLowerCase());
      this.actions = this.actionsRec.filter((attr) => {
        return filterModelStr(attr.name, this.search);
      });
    },
  },
};
</script>

<style>
.height-100 {
  height: 100%;
}
</style>