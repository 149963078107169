<template>
  <v-dialog v-bind:value="value" persistent max-width="1000">
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Cadastrar Automação</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-1">
          <v-col cols="auto">
            <v-checkbox label="Ativa" v-model="automation.enabled"></v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              v-model="automation.name"
              label="Nome da automação"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- linha das tabelas -->
        <v-row>
          <!-- tabela de oquefazer -->
          <v-col>
            <TableWhen
              :when="automation.when"
              @dialogWhen="dialogWhen = true"
              @remove="automation.when.splice($event, 1)"
            />
          </v-col>
          <!-- tabela de quandofazer -->
          <v-col>
            <TableDo
              :toDo="automation.do"
              @dialogDo="dialogDo = true"
              @remove="automation.do.splice($event, 1)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="mx-2" text @click="save"> Salvar </v-btn>
        <v-btn color="warning" class="mx-2" text @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--  quando fazer -->
    <DialogWhen :devices="devices" v-model="dialogWhen" @save="addWhen" />
    <!--  dialog oque fazer -->
    <DialogDo :devices="devices" v-model="dialogDo" @save="addDo" />
  </v-dialog>
</template>

<script>
import DialogWhen from "./DialogWhen.vue";
import DialogDo from "./DialogDo.vue";
import TableDo from "./TableDo.vue";
import TableWhen from "./TableWhen.vue";
export default {
  name: "DialogFromAutomation",
  components: {
    DialogWhen,
    DialogDo,
    TableDo,
    TableWhen,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Array,
      default: () => [],
    },
    auto: {
      type: Object,
    },
  },
  data: () => ({
    dialogWhen: false,
    dialogDo: false,
    automation:{
      enabled: true,
      name: "",
      when: [],
      do: [],
    }
  }),
  methods: {
    // Metodos
    addWhen(data) {
      // console.log("addWhen", data);
      this.automation.when.push(data);
      // console.log(this.automation);
    },
    addDo(data) {
      // console.log("addDo", data);
      this.automation.do.push(data);
      // console.log(this.automation);
    },
    close() {
      this.automation = {
        enabled: true,
        name: "",
        when: [],
        do: [],
      };
      this.$emit("input", false);
      this.$emit("cancel");
    },
    save() {
      console.log(this.automation);
      if(this.automation.name == ""){
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Nome da automação é obrigatório");
        return;
      }
      if(this.automation.when.length == 0){
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "É necessário ter pelo menos uma condição");
        return;
      }
      if(this.automation.do.length == 0){
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "É necessário ter pelo menos uma ação");
        return;
      }
      this.automation.callback = () =>{
        this.close();
      };
      this.$emit("save", this.automation);
      // this.close();
    },
    transformDelay2Millis(delay) {
      let total = delay.hours * 3600 + delay.minutes * 60 + delay.seconds;
      return total * 1000;
    },
  },
  watch: {
    value: function (snackbar) {
      if(snackbar){
        this.automation = {...this.auto};
      }
    },
  },
};
</script>

<style>
.height-100 {
  height: 100%;
}
</style>