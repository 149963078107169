<template>
  <v-dialog v-bind:value="value" persistent scrollable>
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Quando fazer</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar Janela</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <!-- linha do dispositivo -->
        <v-row>
          <!-- coluna da selecção de dispositivo -->
          <v-col>
            <v-card min-height="500">
              <v-card-title>Selecione o dispositivo</v-card-title>
              <v-card-text>
                <v-card outlined class="px-1">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in devices"
                      :key="index"
                      @click="selectDevice(item)"
                      :class="
                        selectedDevice.id == item.id
                          ? 'light-blue darken-1'
                          : ''
                      "
                    >
                      {{ item.name }}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- coluna da selecção de estado eveto -->
          <v-col>
            <v-card>
              <v-card-title>Selecione o estado / evento</v-card-title>
              <v-card-text>
                <v-text-field
                  label="Pesquisar"
                  outlined
                  class="mb-0"
                  v-model="search"
                  @input="filter"
                >
                </v-text-field>
                <v-simple-table dark v-show="attributes.length">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Descrição</th>
                        <th class="text-left">Estado / Evento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in attributes"
                        :key="index"
                        @click="selectAttribute(item)"
                        :class="
                          selectedAttribute.name == item.name &&
                          selectedAttribute.type == item.type
                            ? 'light-blue darken-1'
                            : ''
                        "
                      >
                        <td>{{ item.name }}</td>
                        <td>
                          {{ item.type == "event" ? "Evento" : "Estado" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <hr v-show="attributes.length" />
                <v-card outlined class="mt-2" v-show="attributes.length">
                  <v-card-text>
                    <v-autocomplete
                      v-model="daysOfWeek"
                      :items="daysOfWeekItems"
                      label="Dias da semana"
                      hint="Selecione os dias da semana que a ação deve ocorrer"
                      persistent-hint
                      outlined
                      multiple
                      chips
                      small-chips
                      deletable-chips
                    >
                    </v-autocomplete>
                  </v-card-text>
                </v-card>
                <hr class="my-1" v-show="attributes.length" />
                <v-card outlined v-show="attributes.length">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-text-field
                          class="mt-2"
                          outlined
                          label="Inicio da janela de execução"
                          hint="Este evento será executado a partir deste horário"
                          persistent-hint
                          type="time"
                          v-model="time.start"
                          v-show="attributes.length"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          class="mt-2"
                          outlined
                          label="Final da janela de execução"
                          hint="Este evento será executado a até este horário"
                          persistent-hint
                          type="time"
                          v-model="time.end"
                          v-show="attributes.length"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save" color="success" class="mx-2" text> Adicionar </v-btn>
        <v-btn @click="close" color="warning" class="mx-2" text>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogWhen",
  props: ["value", "devices"],
  data: () => ({
    type: "device",
    attributes: [],
    attributesRec: [],
    selectedDevice: {},
    selectedAttribute: {},
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    daysOfWeekItems: [
      { value: 0, text: "Dom" },
      { value: 1, text: "Seg" },
      { value: 2, text: "Ter" },
      { value: 3, text: "Qua" },
      { value: 4, text: "Qui" },
      { value: 5, text: "Sex" },
      { value: 6, text: "Sab" },
    ],
    time: {
      start: "00:00",
      end: "23:59",
    },
    search: "",
  }),
  methods: {
    close() {
      this.selectedDevice = {};
      this.selectedAttribute = {};
      this.daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
      this.time = {
        start: "00:00",
        end: "23:59",
      };
      this.attributes = [];
      this.attributesRec = [];
      this.$emit("input", false);
    },
    selectDevice(selected) {
      this.attributes = [];
      this.selectedDevice = selected;
      // let device = this.$store.getters.getDevicesAvailable.find(
      //   (el) => el.class == selected.deviceType
      // );
      // if (device) {
      //   this.attributes = [...device.events, ...device.attributes];
      // }
      this.$http("device/config/" + selected.id).then((response) => {
        if (response.data.events) {
          this.attributes = [
            ...response.data.events,
            ...response.data.attributes,
          ];
          this.attributesRec = [...this.attributes];
          this.search = "";
        }
      });
    },
    selectAttribute(selected) {
      this.selectedAttribute = selected;
    },
    save() {
      let toWhen = {
        device: {
          name: this.selectedDevice.name,
          id: this.selectedDevice.id,
        },
        attributes: this.selectedAttribute,
        daysOfWeek: this.daysOfWeek,
        time: this.time,
      };
      toWhen.or = false;
      this.$emit("save", toWhen);
      this.close();
    },
    filter() {
      const filterModelStr = (str = "", search = "") =>
        str.toLowerCase().includes(search.toLowerCase());
      this.attributes = this.attributesRec.filter((attr) => {
        return filterModelStr(attr.name, this.search);
      });
    },
  },
};
</script>

<style>
</style>