var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Automações cadastradas "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":_vm.loadData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAutomations,"search":_vm.search},scopedSlots:_vm._u([{key:"item.when",fn:function(ref){
var item = ref.item;
return _vm._l((item.when),function(w,idx){return _c('div',{key:idx + 'a'},[_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Dispositivo: ")]),_c('span',{staticClass:"smallText font-weight-medium mx-2"},[_vm._v(_vm._s(w.device.name)+" ")]),(w.attributes.type == 'event')?_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Evento:")]):_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Estado:")]),_c('span',{staticClass:"smallText font-weight-medium mx-2"},[_vm._v(_vm._s(w.attributes.name)+" ")]),(idx + 1 != item.when.length)?_c('span',{staticClass:"smallText font-weight-black mx-1 text-decoration-underline"},[_vm._v(_vm._s(w.or ? "OU" : "E")+" ")]):_vm._e()])})}},{key:"item.do",fn:function(ref){
var item = ref.item;
return _vm._l((item.do),function(w,idx){return _c('div',{key:idx + 'a'},[_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Dispositivo: ")]),_c('span',{staticClass:"smallText font-weight-medium mx-2"},[_vm._v(_vm._s(w.device.name))]),_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Ação:")]),_c('span',{staticClass:"smallText font-weight-medium mx-2"},[_vm._v(_vm._s(w.action.name)+" ")]),(w.delay > 0)?_c('span',{staticClass:"smallText font-weight-black"},[_vm._v("Atrazo:")]):_vm._e(),(w.delay > 0)?_c('span',{staticClass:"smallText font-weight-medium ml-1"},[_vm._v(_vm._s(w.delay / 1000)+" segs ")]):_vm._e()])})}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabled ? "Ativo" : "Inativo")+" ")]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientId ? _vm.$store.getters.getClienteNameById(item.clientId) : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar : "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.excluir(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',[_vm._v("Excluir : "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.runAutomation(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-powershell")])]}}],null,true)},[_c('span',[_vm._v("Executar : "+_vm._s(item.name))])])]}}])})],1)],1)],1),_c('DialogForm',{attrs:{"auto":_vm.automation,"devices":_vm.getDevices},on:{"save":_vm.save,"cancel":_vm.cancel},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.clientId),expression:"$route.query.clientId"}],staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){_vm.dialogForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }