<template>
  <v-card outlined>
    <v-card-title>Quando fazer</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn color="success" class="mx-2" @click="$emit('dialogWhen', true)"
            ><v-icon>mdi-plus-outline</v-icon> Criar
          </v-btn>
          <v-btn color="error" disabled>
            <v-icon>mdi-close-outline</v-icon>
            Remover
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Dispositivo</th>
                  <th class="text-left">Ação</th>
                  <th class="text-left">E/OU</th>
                  <th class="text-left">Remover</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in when" :key="index">
                  <td>{{ item.device.name }}</td>
                  <td>{{ item.attributes.name }}</td>
                  <td>
                    <v-btn @click="changeOr(item)">{{
                      item.or ? "OU" : "E"
                    }}</v-btn>
                  </td>
                  <td>
                    <v-btn icon @click="$emit('remove', index)">
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TableWhen",
  props: ["value", "when"],
  methods: {
    changeOr(item) {
      item.or = !item.or;
    },
  },
};
</script>

<style>
</style>